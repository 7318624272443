import React from 'react';
import SignUpButton from '../components/signupButton';

function Test(props) {
    return (
        <div>
            <SignUpButton/>
        </div>
    );
}

export default Test;